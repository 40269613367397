import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Modal, RecordsTable } from 'dyl-components';

import OpportunitiesHeader from './Header';
import OpportunitiesRow from './OpportunitiesRow';
import AddOpportunityModal from 'shared/modals/AddOpportunityModal';
import FullScreenModalPopUp from 'shared/FullScreenModalPopUp';

const OpportunitiesTable = ({ onFilter, isColumnHidden, onPageChange }) => {
    const [params] = useSearchParams();

    const { count, opportunities = [], isReadingOpportunities } = useSelector(state => state.opportunities);

    const [isAddOpportunityModalOpen, setIsAddOpporunityModalOpen] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    const onOpenAddOpportunityModal = () => {
        setIsAddOpporunityModalOpen(true);
    }

    const onCloseAddOpportunityModal = () => {
        setIsAddOpporunityModalOpen(false);
    }
    return (
        <React.Fragment>
            <RecordsTable
                tableBodyClassName='ModulesListTable'
                isTableLoading={isReadingOpportunities}
                isSearchResultEmpty={count === 0 && (params.has('search') || params.has('start') || params.has('end') || params.has('account_type') || params.has('owned_by'))}
                isTableEmpty={count === 0 && !(params.has('search') || params.has('start') || params.has('end') || params.has('account_type') || params.has('owned_by'))}
                TableHeader={<OpportunitiesHeader isColumnHidden={isColumnHidden} onFilter={onFilter} />}
                TableRows={opportunities.map(opportunity => (
                    <OpportunitiesRow
                        key={opportunity.pipeline_id}
                        opportunity={opportunity}
                        isColumnHidden={isColumnHidden}
                    />
                ))}
                recordsCount={count}
                activePage={params.get('page')}
                onPageChange={onPageChange}
                recordsName='Opportunity'
                onClick={onOpenAddOpportunityModal}
            />
            <Modal open={isAddOpportunityModalOpen} onClose={() => {
                isDirty
                    ? setIsConfirmationOpen(true)
                    : onCloseAddOpportunityModal();
            }}>
                <AddOpportunityModal onOpen={() => { return Promise.resolve(); }} onClose={onCloseAddOpportunityModal} onDirtyChange={(dirty) => setIsDirty(dirty)} />
            </Modal>
            <FullScreenModalPopUp
                header={"Changes not saved"}
                subheader={"Are you sure you want to exit?"}
                isOpen={isConfirmationOpen}
                onConfirm={() => {
                    onCloseAddOpportunityModal();
                    setIsConfirmationOpen(false);
                    setIsDirty(false)
                }}
                onFormClose={() => setIsConfirmationOpen(false)}
                closeIcon={false}
            />
        </React.Fragment>
    );
}

export default OpportunitiesTable;
