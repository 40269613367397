import React, { useContext, useEffect, useState } from 'react';
import { Header } from 'semantic-ui-react';
import { Modal, TableWithHeader } from 'dyl-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ContactsToolbar from './subcomponents/ContactsToolbar';
import ContactsTable from './subcomponents/ContactsTable';
import { useSelector, useDispatch } from 'react-redux';
import usersActions from 'actions/users';
import contactsActions from 'actions/contacts';
import textActions from 'actions/text';
import oauthActions from 'actions/oauth';
import emailTemplatesActions from 'actions/email_templates';
import companyActions from 'actions/company';
import userActions from 'actions/user';
import {
    KEY_CREATED_ON, KEY_OWNED_BY, KEY_LAST_MODIFIED,
    KEY_ACCOUNT
} from './subcomponents/constants';

import './index.scss';
import { KEY_ACTIONS, KEY_CONTACT_NAME, KEY_CONVERT } from 'shared/constants/MODULE_COLUMNS';
import BulkActionsProvider, { BulkActionsContext } from 'shared/context/BulkActionsProvider';
import AddContactModal from 'shared/modals/AddContactModal';
import FullScreenModalPopUp from 'shared/FullScreenModalPopUp';

const Contacts = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [params] = useSearchParams();

    const searchQuery = params.get('search') || '';
    const [search, setSearch] = useState(searchQuery);
    const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
   
    const { user_id } = useSelector(state => ({
        user_id: state.auth.user_id
    }));

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
    }
    
    const onOpenAddContactModal = () => {
        setIsAddContactModalOpen(true);
    }
    const onCloseAddContactModal = () => {
        setIsAddContactModalOpen(false);
    }

    const cancelFunction = () => {
        setSearch("");
        const query = new URLSearchParams(params);
        query.delete('search');
        const query_string = query.toString();
        navigate(`/contacts${query_string ? `?${query_string}` : ''}`,);
    };

    const columnDisplayOptions = [KEY_CREATED_ON, KEY_OWNED_BY, KEY_LAST_MODIFIED, KEY_CONVERT, KEY_ACCOUNT, KEY_ACTIONS, KEY_CONTACT_NAME];
    const defaultColumnsDisplayed = [KEY_CREATED_ON, KEY_OWNED_BY, KEY_LAST_MODIFIED, KEY_CONVERT, KEY_ACCOUNT, KEY_ACTIONS, KEY_CONTACT_NAME];
    const columnsParameter = params.get('columns')?.split(',')?.filter(column => columnDisplayOptions.includes(column)) || [];
    const columns = columnsParameter.length ? columnsParameter : defaultColumnsDisplayed;

    const [, setSelectedContacts, , setAreContactsInAllPagesSelected] = useContext(BulkActionsContext);

    useEffect(() => {
        dispatch(usersActions.readUsers());

        const queryParameters = {
            page: 1,
            limit: 25,
            ...Object.fromEntries(params)
        }

        dispatch(contactsActions.readContacts(queryParameters));
        dispatch(textActions.getPhones());
        dispatch(oauthActions.getIntegrations({ scopes: 'email', include_disabled: false }));
        dispatch(emailTemplatesActions.getTemplates({ page: 1, limit: 60 }))
        dispatch(companyActions.readSignatures());
        dispatch(userActions.viewUserProfile(user_id))
        dispatch(userActions.readSignatures({ user_id }));
        setSelectedContacts([]);
        setAreContactsInAllPagesSelected(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const [filters, setFilters] = useState({
        account_type: params.get('account_type')?.split(','),
        owned_by: params.get('owned_by')?.split(',')
    })

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value
        });
    }

    useEffect(() => {
        const query = new URLSearchParams(params);
        if (search.trim()) {
            query.set('search', search);
        } else {
            query.delete('search');
        }
        const { account_type, owned_by, module } = filters;
        if (account_type?.length > 0) {
            query.set('account_type', account_type.join(','));
        } else {
            query.delete('account_type');
        }
        if (owned_by?.length > 0) {
            query.set('owned_by', owned_by.join(','));
        } else {
            query.delete('owned_by');
        }
        if (module?.length > 0) {
            query.set('module', module.join(','));
        } else {
            query.delete('module');
        }
        const query_string = query.toString();
        navigate(`/contacts${query_string ? `?${query_string}` : ''}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`/contacts${query_string ? `?${query_string}` : ''}`);
    }

    const onSearchSubmit = (value) => {
        const query = new URLSearchParams(params);
        query.set('search', value.trim());
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`/contacts${query_string ? `?${query_string}` : ''}`);
    }

    const isColumnHidden = (column) => {
        return !columns.includes(column);
    }

    const onToggleColumn = async (column) => {
        const updatedColumns = isColumnHidden(column) ? [...columns, column, KEY_CONTACT_NAME] : columns.filter(visibleColumn => visibleColumn !== column);

        const updateColumnsParameter = () => {
            const query = new URLSearchParams(params);
            if (updatedColumns.length) {
                query.set('columns', updatedColumns.join(','));
            } else {
                query.delete('columns');
            }
            const query_string = query.toString();
            navigate(`/contacts${query_string ? `?${query_string}` : ''}`);
        }

        if (!isColumnHidden(column)) {
            const { account_type, owned_by } = filters;

            switch (column) {
                case KEY_OWNED_BY:
                    if (owned_by?.length > 0) {
                        onFilter(null, { name: "owned_by", value: [] });
                    } else {
                        updateColumnsParameter();
                    }
                    break;
                case KEY_ACCOUNT:
                    if (account_type?.length > 0) {
                        onFilter(null, { name: "account_type", value: [] });
                    } else {
                        updateColumnsParameter();
                    }
                    break;
                case KEY_CREATED_ON:
                    if (params.get('start') || params.get('end') || params.get('order_by') === 'created') {
                        const query = new URLSearchParams(params);
                        query.delete('start');
                        query.delete('end');
                        query.delete('order_by');
                        query.delete('order');
                        if (updatedColumns.length) {
                            query.set('columns', updatedColumns.join(','));
                        } else {
                            query.delete('columns');
                        }
                        const query_string = query.toString();
                        navigate(`/contacts${query_string ? `?${query_string}` : ''}`);
                    } else {
                        updateColumnsParameter();
                    }
                    break;
                case KEY_LAST_MODIFIED:
                    if (params.get('order_by') === 'activity') {
                        const query = new URLSearchParams(params);
                        query.delete('order_by');
                        query.delete('order');
                        if (updatedColumns.length) {
                            query.set('columns', updatedColumns.join(','));
                        } else {
                            query.delete('columns');
                        }
                        const query_string = query.toString();
                        navigate(`/contacts${query_string ? `?${query_string}` : ''}`);
                    } else {
                        updateColumnsParameter();
                    }
                    break;
                default:
                    updateColumnsParameter();
            }
        } else {
            updateColumnsParameter();
        }
    }

    return (
        <React.Fragment>
        <div className='ContactsPage'>
            <Header className='ContactsPage__PageHeader'>
                <Header.Content>Contacts</Header.Content>
            </Header>
            <TableWithHeader
                header={(
                    <ContactsToolbar
                        search={searchQuery}
                        onChangeSearch={onChangeSearch}
                        onSearchSubmit={onSearchSubmit}
                        cancelFunction={cancelFunction}
                        isColumnHidden={isColumnHidden}
                        onToggleColumn={onToggleColumn}
                        onOpenAddContactModal={onOpenAddContactModal}
                    />
                )}
                table={(
                    <ContactsTable
                        isColumnHidden={isColumnHidden}
                        onFilter={onFilter}
                        onPageChange={onPageChange}
                        onOpenAddContactModal={onOpenAddContactModal}
                    />
                )}
            />
        </div>
        <Modal open={isAddContactModalOpen} onClose={() => {
                isDirty
                    ? setIsConfirmationOpen(true)
                    : onCloseAddContactModal();
            }}>
                <AddContactModal onOpen={() => { return Promise.resolve(); }} onClose={onCloseAddContactModal} onDirtyChange={(dirty) => setIsDirty(dirty)} />
        </Modal>
            <FullScreenModalPopUp
                header={"Changes not saved"}
                subheader={"Are you sure you want to exit?"}
                isOpen={isConfirmationOpen}
                onConfirm={() => {
                    onCloseAddContactModal();
                    setIsConfirmationOpen(false);
                    setIsDirty(false)
                }}
                onFormClose={() => setIsConfirmationOpen(false)}
                closeIcon={false}
            />
        </React.Fragment>
    );
}

export default function ContactsContainer() {
    return (
        <BulkActionsProvider>
            <Contacts />
        </BulkActionsProvider>
    )
};
