import userActions from "actions/user";
import { generateResolver, STATUS_TYPES, Button, VALIDATORS, Notification } from "dyl-components";
import { useController, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "semantic-ui-react";

const ResendEmailForm = ({ email, onClose, idOfUserToUpdate, customer_id }) => {
    const { control, handleSubmit, formState: { isValid, isDirty }, setError } = useForm({
        mode: 'onChange',
        resolver: generateResolver({
            email: VALIDATORS.EMAIL_ADDRESS().required("Email is required"),
        }),
        defaultValues: {
            email: email,
        },
    });
    const isResendingEmail = useSelector((state) => state.user.isResendingEmail || state.user.isUpdatingUserEmail);
    const isCheckingDuplicates = useSelector((state) => state.user.isFindingUserByEmail);
    const { field, fieldState: { error } } = useController({ control, name: 'email' });
    const dispatch = useDispatch();

    const onSubmit = async (data) => {
        try {
            if (isDirty) {
                await dispatch(userActions.updateEmail(idOfUserToUpdate, { email: data.email }, { customer_id }));
            }
            await dispatch(userActions.resendEmail({ email: data.email }, { newUser: true }));
            Notification.alert(`Email sent to ${data.email}!`, STATUS_TYPES.SUCCESS);
            dispatch(userActions.getSummarizedUsers(customer_id));
            onClose();
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to resend email", STATUS_TYPES.ERROR);
        }
    };

    return (
        <Form noValidate loading={isResendingEmail}>
            <Form.Input 
                label="Send to" 
                required 
                type="email" 
                placeholder="Type email here" 
                value={field.value} 
                onChange={async (_, { value }) => {
                    field.onChange({ target: { name: field.name, value } });
                    if (value !== "" && (await VALIDATORS.EMAIL_ADDRESS().isValid(value))) {
                        const { customer_id, user_id } = await dispatch(
                            userActions.findByEmail("", {
                                email: value,
                            })
                        );
                        const hasNoDuplicates = user_id === idOfUserToUpdate || !(customer_id && user_id);
                        if (!hasNoDuplicates) {
                            await setError(field.name, {
                                type: "unique_email",
                                message: "Email already exists",
                            });
                        }
                    }
                }} 
                error={error?.message}
                loading={isCheckingDuplicates}
            />
            <Button disabled={isResendingEmail || !isValid || isCheckingDuplicates} type="submit" onClick={handleSubmit(onSubmit)} primary>Send</Button>
        </Form>
    );
};

export default ResendEmailForm;
