import React from "react";
import { Form } from "semantic-ui-react";
import './index.scss'
import { Controller } from "react-hook-form";
import { PhoneUtil } from "utils";

const DELIVERY_METHOD_OPTIONS = [
    // {key: "sms", value: "sms", text: "SMS"},
    {key: "voice", value: "voice", text: "Voice"},
]

const VerifyExternalNumberForm = ({ control, verifyingPhone }) => {
    const isExtDisabled = (verifyingPhone?.forward?.type === "company") || (!verifyingPhone.shared && !verifyingPhone.forward);

    return (
        <Form noValidate loading={false}>
            <div style={{display: 'flex', gap: 20}}>
                <Controller
                    name='phone'
                    control={control}
                    render={({ field: { name, value }, fieldState: { error } }) => (
                        <Form.Input
                            name={name}
                            value={PhoneUtil.formatPhoneNumber(value)}
                            label="Number"
                            required
                            className="ExternalNumbers__verifyField"
                            error={error?.message}
                            readOnly
                        />
                    )}
                />
                <Controller
                    name={"method"}
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Select
                            name={name}
                            value={value}
                            onChange={(_, { value }) => {
                                onChange({ target: { name, value } }) 
                            }}
                            placeholder='Select delivery method'
                            selectOnBlur={false}
                            options={DELIVERY_METHOD_OPTIONS}
                            label="Delivery Method"
                            className="ExternalNumbers__verifyField"
                            required
                        />
                    )}
                />
            </div>
            <div style={{display: 'flex', gap: 20}}>
                <Controller
                    name='extension'
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <Form.Input
                            name={name}
                            value={value}
                            label="External Ext."
                            placeholder='Type external ext.'
                            className="ExternalNumbers__verifyField"
                            error={error?.message}
                            onChange={(_, { value }) => {
                                onChange({ target: { name, value } }) 
                            }}
                            disabled={isExtDisabled}
                        />
                    )}
                />
                <Form.Field 
                    className="ExternalNumbers__verifyField"
                />
            </div>
        </Form>
    )
}

export default VerifyExternalNumberForm;
