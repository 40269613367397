import React, { useContext, useState } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { DateRangePowerSelector, ModulesFilter, NumberRangePowerSelector, PowerSelector, PowerSelectors } from 'dyl-components';

import { BulkActionsContext } from 'shared/context/BulkActionsProvider';
import { KEY_ACCOUNT_CO_OWNER, KEY_ACCOUNT_NAME, KEY_ACCOUNT_OWNER, KEY_CREATED, KEY_CREATED_BY, KEY_DUE_ON, KEY_INVOICE_ID, KEY_INVOICE_STATUS, KEY_NUMBER_PRODUCTS, KEY_ORDER_ID, KEY_PAYMENT_TYPE, KEY_PRODUCTS, KEY_SUBTOTAL, KEY_TERM } from 'shared/constants/INVOICE_REPORT_COLUMNS';

const COLUMN_ROWS = [
    [
        { value: KEY_CREATED, label: "Created" },
        { value: KEY_SUBTOTAL, label: "Subtotal" },
    ],
    [
        { value: KEY_CREATED_BY, label: "Created By" },
        { value: KEY_PAYMENT_TYPE, label: "Payment Type" },
    ],
    [
        { value: KEY_DUE_ON, label: "Due On" },
        { value: KEY_TERM, label: "Term" },
    ],
    [
        { value: KEY_INVOICE_ID, label: "Invoice ID", isForcedChecked: true },
        { value: KEY_NUMBER_PRODUCTS, label: "# Products" },
    ],
    [
        { value: KEY_ORDER_ID, label: "Order ID" },
        { value: KEY_PRODUCTS, label: "Products" },
    ],
    [
        { value: KEY_INVOICE_STATUS, label: "Invoice Status" },
        { value: KEY_ACCOUNT_NAME, label: "Account Name" },
    ],
    [
        { value: KEY_ACCOUNT_OWNER, label: "Account Owner" },
        { value: KEY_ACCOUNT_CO_OWNER, label: "Account Co-Owner" }
    ]
];

const InvoicesToolbar = ({ isColumnHidden, onToggleColumn }) => {
    const [selectedInvoices, , areInvoicesInAllPagesSelected] = useContext(BulkActionsContext);

    const hasSelectedQuotesOrOrders = selectedInvoices.length || areInvoicesInAllPagesSelected;

    const [, setIsBulkActionsModalOpen] = useState(false);

    const onOpenBulkActionsModal = () => {
        setIsBulkActionsModalOpen(true);
    }

    return (
        <Grid className='InvoicesToolbar'>
            <Grid.Row>
                <Grid.Column>
                    <PowerSelectors>
                        <DateRangePowerSelector 
                            label={"Created"}
                            name={"created"}
                        />
                        <DateRangePowerSelector 
                            label={"Due On"}
                            name={"due_on"}
                        />
                        <PowerSelector 
                            label={"Account Owner"}
                            multiple
                            name={"account_owner"}
                            options={[]}
                        />
                        <PowerSelector 
                            label={"Account Co-Owner"}
                            multiple
                            name={"account_co_owner"}
                            options={[]}
                        />
                        <PowerSelector
                            label={"Created By"}
                            multiple
                            name={"created_by"}
                            options={[]}
                        />
                        <PowerSelector 
                            label={"Contact Name"}
                            multiple
                            name={"contact_name"}
                            options={[]}
                        />
                        <PowerSelector 
                            label={"Account Name"}
                            multiple
                            name={"account_name"}
                            options={[]}
                        />
                        <NumberRangePowerSelector 
                            label={"# Products"}
                            name={"number_products"}
                            min={1}
                        />
                        <PowerSelector 
                            label={"Products"}
                            multiple
                            name={"products"}
                            options={[]}
                        />
                        <NumberRangePowerSelector 
                            label={"Subtotal"}
                            name={"subtotal"}
                            min={1}
                            isCurrency
                        />
                        <PowerSelector 
                            label={"Payment Method"}
                            multiple
                            name={"payment_method"}
                            options={[]}
                        />       
                        <PowerSelector 
                            label={"Invoice Status"}
                            multiple
                            name={"invoice_status"}
                            options={[]}
                        />        
                        <PowerSelector 
                            label={"Term"}
                            multiple
                            name={"term"}
                            options={[]}
                        />                
                    </PowerSelectors>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal' verticalAlign='middle'>
                <Grid.Column style={{ marginLeft: "-2.75em" }} width={1}>
                    <ModulesFilter
                        hasSearchBar={false}
                        columnRows={COLUMN_ROWS}
                        columnCount={2}
                        isColumnHidden={isColumnHidden}
                        onToggleColumn={onToggleColumn}
                    />
                </Grid.Column>
                <Grid.Column />
                <Grid.Column width={4} floated='right'>
                    <Button floated='right' className='InvoicesToolbar__InvoicesButton' disabled={!hasSelectedQuotesOrOrders} onClick={onOpenBulkActionsModal}>
                        Bulk Action
                    </Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default InvoicesToolbar;
