import { Grid, Header } from "semantic-ui-react";
import React from "react";
import { MathUtils, StringUtils } from "utils";
import { useSelector } from "react-redux";

const QuoteTotals = ({ quote, isInvoice, cart }) => {
    const { fund_type } = useSelector(
        (state) => isInvoice ? state.order.order?.account || {} : {}
    );
    const isDemoAccount = fund_type === "demo";
    const { grossTotal, totalDiscount, totalTax, totalFee, subtotal } = isDemoAccount
        ? {
            grossTotal: 0,
            totalDiscount: 0,
            totalTax: 0,
            totalFee: 0,
            subtotal: 0,
        }
        : cart
            .filter((item) => !Boolean(item.removed))
            .reduce(
                (a, item) => {
                    const productDetails = quote.quote_summary.find(
                        (quoteItem) =>
                            quoteItem.product_id === item.id &&
                            quoteItem.product_variation_id === item.variation_id
                    );
                    const price = (() => {
                        const isOneTime =
                            !productDetails.price_data?.model?.includes(
                                "recurring"
                            );
                        const pricingModel = productDetails.price_data.model;
                        if (isOneTime) {
                            if (!pricingModel.includes("volume")) {
                                return pricingModel === "usage"
                                    ? productDetails.price_data.price.price
                                    : productDetails.price_data.price;
                            }
                            return (
                                productDetails.price_data.price.find(
                                    (range) =>
                                        item.quantity >= range.start &&
                                        item.quantity <=
                                        (range.end || Number.POSITIVE_INFINITY)
                                )?.price || 0
                            );
                        }
                        if (!pricingModel.includes("volume")) {
                            return (
                                productDetails.price_data.price[item.pricing_schedule]
                                    ?.price || 0
                            );
                        }
                        const frequency =
                            productDetails.price_data.price[item.pricing_schedule]
                                ?.volumes || [];
                        return (
                            frequency.find(
                                (range) =>
                                    item.quantity >= range.start &&
                                    item.quantity <=
                                    (range.end || Number.POSITIVE_INFINITY)
                            )?.price || 0
                        );
                    })();

                    const {
                        totalTax,
                        totalFee,
                        grossTotal,
                        subtotal,
                        discountAmount,
                    } = MathUtils.calculateItemTotals({
                        price,
                        additional_price:
                            productDetails.product_additional_price || 0,
                        quantity: item.quantity,
                        discount: item.discount || 0,
                        addons: productDetails.addon
                            .filter(
                                (addon) =>
                                    item.addons.includes(addon.id) && !addon.unlinked
                            )
                            .map((addon) => ({
                                price: addon.current_price || 0,
                            })),
                        taxes: productDetails.tax.map((tax) => ({
                            amount: tax.current_amount,
                            percent: tax.tax_fee_percent,
                        })),
                        fees: productDetails.fee.map((fee) => ({
                            amount: fee.current_amount,
                            percent: fee.tax_fee_percent,
                        })),
                    });

                    return {
                        grossTotal: a.grossTotal + grossTotal,
                        totalDiscount: a.totalDiscount + discountAmount,
                        totalTax: a.totalTax + totalTax,
                        totalFee: a.totalFee + totalFee,
                        subtotal: a.subtotal + subtotal,
                    };
                },
                {
                    grossTotal: 0,
                    totalDiscount: 0,
                    totalTax: 0,
                    totalFee: 0,
                    subtotal: 0,
                }
            );

    return (
        <Grid divided="vertically">
            <Grid.Row>
                <Grid.Column>
                    <Header>Today's {isInvoice ? "Invoice" : "Quote"}</Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
                <Grid.Column>
                    <Header size="small">Total</Header>
                </Grid.Column>
                <Grid.Column textAlign="right">
                    <Header color="primary" size="small">
                        $
                        {StringUtils.formatDecimal(
                            `${MathUtils.roundDecimals(grossTotal)}`
                        , true)}
                    </Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Grid>
                        <Grid.Row columns="2">
                            <Grid.Column>
                                <Header size="small">Discount</Header>
                            </Grid.Column>
                            <Grid.Column textAlign="right">
                                <Header color="primary" size="small">
                                    -${StringUtils.formatDecimal(
                                        `${MathUtils.roundDecimals(totalDiscount)}`
                                    , true)}
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns="2">
                            <Grid.Column>
                                <Header size="small">Total Fee</Header>
                            </Grid.Column>
                            <Grid.Column textAlign="right">
                                <Header color="primary" size="small">
                                    $
                                    {StringUtils.formatDecimal(
                                        `${MathUtils.roundDecimals(totalFee)}`
                                    , true)}
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns="2">
                            <Grid.Column>
                                <Header size="small">Total Tax</Header>
                            </Grid.Column>
                            <Grid.Column textAlign="right">
                                <Header color="primary" size="small">
                                    $
                                    {StringUtils.formatDecimal(
                                        `${MathUtils.roundDecimals(totalTax)}`
                                    , true)}
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
                <Grid.Column>
                    <Header size="small">Subtotal</Header>
                </Grid.Column>
                <Grid.Column textAlign="right">
                    <Header color="primary" size="small">
                        $
                        {StringUtils.formatDecimal(
                            `${MathUtils.roundDecimals(subtotal)}`
                        , true)}
                    </Header>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default QuoteTotals;
