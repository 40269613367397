import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header, Popup, Segment, Form, Icon } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./index.scss";
import RouteCustomizationForm from "shared/forms/RouteCustomizationForm";
import pbxConfigActions from "actions/pbx_config";
import { ButtonLink, ClippedContent, generateResolver, Notification, STATUS_TYPES, yup } from "dyl-components";
import contactActions from "actions/contact";
import accountActions from "actions/account";

const RouteSection = () => {
    const params = useParams();
    const { contact_id, account_id } = params;
    const id = contact_id || account_id;
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        dispatch(pbxConfigActions.getDestinations());
        dispatch(pbxConfigActions.getPreferences());
    }, [dispatch]);

    const { routing, isUpdatingRoute, isReadingDestinations, destinations, preferences } =
        useSelector((state) => {
            return {
                routing: contact_id
                    ? state.contact.contact?.routing
                    : state.account.account?.routing,
                isUpdatingRoute: id === state.contact.contactBeingUpdated,
                isReadingDestinations: state.pbx_config.isReadingDestinations,
                destinations: state.pbx_config.destinations || {},
                preferences: state.pbx_config.preferences
            };
        });

    const {
        control,
        handleSubmit,
        formState: { isDirty, isValid },
        reset,
    } = useForm({
        mode: "onChange",
        defaultValues: {
            routing: routing || null,
        },
        resolver: generateResolver({
            routing: yup.string().nullable()
        })
    });

    if (!preferences?.cid_routing) {
        return null;
    }

    const onCancel = () => {
        reset();
        setIsOpen(false);
    };

    const onSave = async (data) => {
        try {
            await dispatch(contactActions.updateRouting(id, null, { route: data.routing }))
            Notification.alert("Successfully updated routing!", STATUS_TYPES.SUCCESS);
            if (contact_id) {
                dispatch(contactActions.readContact(contact_id))
            } else if (account_id) {
                dispatch(accountActions.readAccount(account_id));
            }
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to update routing", STATUS_TYPES.ERROR);
        }
    };

    const destinationCategories = [
        {
            key: "ring_group",
            value: "ring_group",
            text: "Ring Groups",
        },
        {
            key: "call_queue",
            value: "call_queue",
            text: "Call Queues",
        },
        {
            key: "direct_extension",
            value: "direct_extension",
            text: "Direct Extensions",
        },
        {
            key: "forwarding_number",
            value: "forwarding_number",
            text: "Forwarding Numbers",
        },
        {
            key: "ivr",
            value: "ivr",
            text: "IVR Prompts",
        },
        {
            key: "company_voicemail",
            value: "company_voicemail",
            text: "Company VM Box",
        },
        {
            key: "personal_voicemail",
            value: "personal_voicemail",
            text: "Personal VM Box",
        },
    ].map((category) => {
        const options = destinations[category.value]?.map((destination) => ({
            key: destination.destination_id,
            value: destination.fs_destination,
            text: destination.label,
        })) || [];
        return ({
            ...category,
            options: options,
            disabled: options.length === 0
        });
    });

    const routingDetails = (() => {
        if (!routing) {
            return null;
        }
        const [parent_value, child_value] = routing.split("/");
        const category = destinationCategories.find(
            (destinationCategory) => destinationCategory.value === parent_value
        );
        const destination = category?.options.find(
            (destination) => destination.value === child_value
        );
        return {
            parent_value,
            parent_name: category?.text,
            child_value,
            child_name: destination?.text,
        };
    })();

    return (
        <Popup
            disabled={isReadingDestinations}
            trigger={
                <Segment>
                    <div style={{ display: "flex" }}>
                        <Header style={{ margin: 0, flex: 1 }} as="h4">
                            Route
                        </Header>
                        {!isReadingDestinations ? (
                            routingDetails && routingDetails.parent_name ? (
                                <ButtonLink className="Routing__value">
                                    <ClippedContent>
                                        <span>
                                        {routingDetails.parent_name}/
                                        {routingDetails.child_name}
                                        </span>
                                    </ClippedContent>
                                </ButtonLink>
                            ) : (
                                <ButtonLink>
                                    No Destination
                                </ButtonLink>
                            )
                        ) : (
                            <Icon className="fas fa-circle-notch" loading />
                        )}
                    </div>
                </Segment>
            }
            className="RouteSection__popup"
            on="click"
            content={
                <Form loading={isUpdatingRoute}>
                    <div>
                        <Header as="h4">Add Route</Header>
                        <RouteCustomizationForm
                            control={control}
                            handleSubmit={handleSubmit}
                            isDirty={isDirty}
                            isValid={isValid}
                            destinations={destinationCategories}
                            onSave={onSave}
                            onCancel={onCancel}
                        />
                    </div>
                </Form>
            }
            position="bottom center"
            flowing
            basic
            open={isOpen}
            onOpen={() => setIsOpen(true)}
        />
    );
};

export default RouteSection;
