import React, { useRef, useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FileInput, RichTextEditor, Notification, TEXT_EDITOR_MODES, STATUS_TYPES, generateResolver, yup, VALIDATORS } from 'dyl-components';
import { Segment, Button, Grid, Header, Popup, Icon, Divider, Container, Form, ListItem, Loader, Input } from 'semantic-ui-react';
import CategorizedTemplates from 'shared/NewTasksTab/subcomponents/CategorizedTemplates';
import PrintComponent from 'shared/PrintComponent';
import PreviewEmailTemplateModal from 'shared/modals/PreviewEmailTemplateModal';
import templateCategoryActions from 'actions/template_category';
import emailActions from 'actions/email';
import userActions from 'actions/user';
import companyActions from 'actions/company';
import { handleChangeTemplate, personalizedVariables, getEmailPreview, determineSignature, disabledSignature, sendEmailAttachment, SIGNATURE_OPTIONS, personalizeButtons } from 'utils/EmailFormUtils';
import FileUtils, { FILE_CATEGORIES } from 'utils/FileUtils';
import '../index.scss';

const FirstEmail = ({
    editorRef = React.createRef(),
    dropzoneRef = React.createRef(),
    readEmails,

    contact_id,
    account,
    accountId,
    hideTitleBorder,
    isAccountModal,
    isModal = false,
    toolbarClassName
}) => {
    const componentRef = useRef();
    const dispatch = useDispatch();

    const accountIdParam = useParams().account_id;
    const account_id  = !isAccountModal ? accountIdParam : accountId;

    const isSendingEmail = useSelector(state => state.email.isSendingEmail);
    const categorized_templates = useSelector(state => state.template_category.categorized_templates);
    const userProfile = useSelector(state => state.user.userProfile);
    const loadContact = useSelector(state => isModal ? state.send_email.modal : state.send_email);
    //load contact if not passed by prop (emailModal)
    const contactInfo = loadContact;
    const { location: contactLocation } = contactInfo;
    const user_id = useSelector(state => state.auth.user_id);
    const userSignaturePreference = useSelector(state => state.user.signature_preferences.new_email);
    const userSignatures = useSelector(state => state.user.signatures);
    const companySignaturePreference = useSelector(state => state.company.signature_preferences.new_email);
    const companySignatures = useSelector(state => state.company.signatures);
    const activeIntegration = useSelector(state => state.oauth_integrations.activeIntegration);


    const [isPersonalizing, setPersonalize] = useState(false);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

    const [emailToOptions, setEmailToOptions] = useState([]);
    const [emailCcOptions, setEmailCcOptions] = useState([]);
    const [emailBccOptions, setEmailBccOptions] = useState([]);

    const [userSignature, setUserSignature] = useState(null);
    const [companySignature, setCompanySignature] = useState(null);
    const [isDisabledSignature, setDisabledSignature] = useState([]);

    const { formState: { isDirty, isValid, errors }, control, setValue, getValues, setError, trigger } = useForm({
        mode: 'onChange',
        defaultValues: {
            to: [],
            from: '',
            cc: [],
            bcc: [],
            subject: '',
            signature: '',
            body: '',
            attachments: []
        },
        resolver: generateResolver({
            subject: yup.string().required('This field is required').no_whitespace_only().no_excessive_whitespaces().maxlength(256),
            to: yup.array().min(1, "This field is required"),
            cc: yup.array().of(VALIDATORS.EMAIL_ADDRESS()),
            bcc: yup.array().of(VALIDATORS.EMAIL_ADDRESS()),
            body: yup.string().maxlength(384000).transform(value => {
                return value.replace(/(<([^>]+)>)/gi, '');
            }).no_whitespace_only().no_excessive_whitespaces(),
            attachments: FileUtils.getFilesizeValidator(FILE_CATEGORIES.EMAIL)
        })
    });
    const hasErrors = Object.keys(errors).length > 0;

    const emailValidationPattern = /^\w+([.-]?\w+)(\+\d+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const handleAdditionCc = (_, { value }) => {
        setEmailCcOptions((prevOptions) => [
            { text: value, value: value, key: value, invalid: !emailValidationPattern.test(value) },
            ...prevOptions
        ]);
    };

    const handleAdditionBcc = (_, { value }) => {
        setEmailBccOptions((prevOptions) => [
            { text: value, value: value, key: value, invalid: !emailValidationPattern.test(value) },
            ...prevOptions
        ]);
    };

    const personalize = (variable) => {
        setPersonalize(false);
        const { body } = getValues();
        personalizedVariables(variable, editorRef, body, setValue);
    }

    const handleSend = () => {
        if (getValues().subject.trim() === '') return;
        let formValues = { ...getValues() };
        formValues.body = getEmailPreview(
            formValues,
            userSignature,
            companySignature,
            contactInfo,
            userProfile,
            contactLocation,
            account
        );
        onSend(formValues, emailToOptions);
    }


    const onSend = async (values, emailToOptions) => {
        const { body, subject, attachments, to, cc, bcc } = values;
        let toName;

        if (account_id) {
            toName = account?.account?.name || '';
        } else {
            const { first_name, last_name } = contactInfo.contact;
            toName = `${first_name} ${last_name}`;
        }

        //bundle emails
        const toEmails = to.length !== 0 ? to.map(email => {
            return {
                name: toName, //derives from contact first and last or account name
                email: email
            }
        }) : [];
        const ccEmails = cc.length !== 0 ? cc.map(email => {
            let emailExist = emailToOptions.find(e => e.value === email);
            return {
                name: emailExist ? toName : '', //derives from contact or no name if email added
                email: email
            }

        }) : [];
        const bccEmails = bcc.length !== 0 ? bcc.map(email => {
            return {
                // name: ONLY added emails, no names 
                email: email
            }
        }) : [];
        const emailToSend = {
            body,
            subject,
            from: {
                name: activeIntegration.name,
                email: activeIntegration.email,
            },
            to: toEmails,
            cc: ccEmails,
            bcc: bccEmails,
            body_type: 'html',
        }

        if (attachments) {
            await sendEmailAttachment(attachments, emailToSend);
        }

        try {
            await dispatch(emailActions.send([emailToSend]))
            Notification.alert('Succesfully sent!', STATUS_TYPES.SUCCESS, true);
            setTimeout(() => { readEmails() }, 2000)
        } catch {
            Notification.alert('Problem sending the email!', STATUS_TYPES.ERROR, true);
        }
    }

    useEffect(() => {
        let hasUserSignature = false;
        let hasCompanySignature = false;

        if (userSignaturePreference) {
            const userSignature = userSignatures.find(signature => signature.id === userSignaturePreference);
            setUserSignature(userSignature?.content);
            hasUserSignature = true;
        }
        if (companySignaturePreference) {
            const companySignature = companySignatures.find(signature => signature.id === companySignaturePreference);
            setCompanySignature(companySignature?.content);
            hasCompanySignature = true;
        }
        setValue('signature', determineSignature(hasUserSignature, hasCompanySignature));
        setDisabledSignature(disabledSignature(hasUserSignature, hasCompanySignature));
    }, [userSignaturePreference, userSignatures, companySignaturePreference, companySignatures, setValue, setDisabledSignature]);

    useEffect(() => {
        const emailValidationPattern = /^\w+([.-]?\w+)(\+\d+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

        let allToEmails;
        let allToEmailsMapped;
        let allCCEmailsMapped;

        if ((account_id && !isModal) || isAccountModal) {
            let accountEmails = account.account?.emails || [];
            const contactsAssociated = account.contactsForPinning ? account.contactsForPinning.filter(({ email }) => { return email.email !== undefined }) : [];
            allToEmails = accountEmails ? accountEmails.find(email => email.main)?.email : accountEmails ? accountEmails[0].email : [];
            allToEmailsMapped = accountEmails ? accountEmails.map(email => {
                return {
                    key: email?.email,
                    value: email?.email,
                    text: email?.email,
                }
            }) : [];

            if (allToEmails?.length === 0) {
                setError('to', { type: 'to_required', message: 'Add account email' })
            } else {
                allCCEmailsMapped = contactsAssociated.map(email => {
                    return {
                        key: email?.email.id,
                        value: email?.email.email,
                        text: email?.email.email,
                        invalid: !emailValidationPattern.test(email.email)
                    }
                })
            }

        } else {
            allToEmails = contactInfo.email.find(email => email.main)?.email || contactInfo.email[0]?.email || '';
            allToEmailsMapped = contactInfo.email.map(email => {
                return {
                    key: email.id,
                    value: email.email,
                    text: email.email,
                    invalid: !emailValidationPattern.test(email.email)
                }
            })
            allCCEmailsMapped = allToEmailsMapped;
        }

        setValue('to', [allToEmails]);
        setValue('from', activeIntegration.email || '');
        setValue('cc', []);
        setValue('bcc', []);
        setEmailToOptions(allToEmailsMapped);
        setEmailCcOptions(allCCEmailsMapped);

    }, [contactInfo, activeIntegration, setValue, account, setError, account_id, isModal, isAccountModal]);

    useEffect(() => {
        dispatch(companyActions.readSignatures());
        dispatch(templateCategoryActions.readCategorizedTemplates({ category: 'email' }));
        dispatch(userActions.readSignaturePreferences({ user_id }));
        dispatch(companyActions.readSignaturePreferences());
        dispatch(userActions.viewUserProfile(user_id));
        dispatch(userActions.readSignatures({ user_id }));
    }, [user_id, dispatch, contact_id, account]);

    const renderLabel = (label) => {
        return ({
            ...(!label.invalid ? {} : { color: 'red' }),
            content: label.text
        });
    };

    return (
        <React.Fragment>
            {isSendingEmail &&
                <Loader active>Preparing to send email...</Loader>
            }
            <Segment className={`EmailThread${hideTitleBorder ? '__NoBorder' : ''}`} size='tiny' loading={false}>
                {!hideTitleBorder && (
                    <>
                        <Grid columns='equal' className='EmailThread__headerContainer'>
                            <Grid.Row>
                                <Grid.Column width={12}>
                                    <Header as='h3'>Send Email</Header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Divider />
                    </>
                )}
                <PrintComponent
                    ref={componentRef}
                    content={
                        <Grid>
                            <Container className='EmailForm__container'>
                                <Form>
                                    <Form.Group>
                                        <ListItem
                                            className='EmailThread__Row--input'
                                            description={
                                                <Controller
                                                    name='to'
                                                    control={control}
                                                    render={({ field: { onChange, value, name }, fieldState: { error } }) =>
                                                        <Form.Dropdown
                                                            label={<label><Icon name='user' color='black' /> To</label>}
                                                            options={emailToOptions}
                                                            onChange={(_, { value }) => { onChange({ target: { name: name, value: value } }) }}
                                                            selection
                                                            fluid
                                                            multiple
                                                            search
                                                            placeholder='Select an email'
                                                            value={value}
                                                            required
                                                            error={error && error.message && {
                                                                content: error.message,
                                                                pointing: 'below'
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                        />
                                        <ListItem
                                            className='EmailThread__Row--input'
                                            description={
                                                <Controller
                                                    name='from'
                                                    control={control}
                                                    render={({ field: { onChange, value, name }, fieldState: { error } }) =>
                                                        <Form.Input
                                                            readOnly
                                                            width={5}
                                                            label='From'
                                                            onChange={(_, { value: selected }) => { onChange({ target: { name: 'from', value: selected } }) }}
                                                            value={value}
                                                        // error={error && error.message && {
                                                        //   content: error.message,
                                                        //   pointing: 'below'
                                                        // }}
                                                        // placeholder='Select an email'
                                                        // selectOnBlur={false}
                                                        // options={emailCcOptions}
                                                        />
                                                    }
                                                />
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <ListItem
                                            className='EmailThread__Row--input'
                                            description={
                                                <Controller
                                                    name='cc'
                                                    control={control}
                                                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                        <Form.Dropdown
                                                            className='EmailThread__CC'
                                                            label={<label><Icon name='user' color='black' /> Cc</label>}
                                                            options={emailCcOptions}
                                                            onChange={(_, { value: selected }) => { onChange({ target: { name: name, value: selected } }) }}
                                                            selection
                                                            fluid
                                                            multiple
                                                            allowAdditions
                                                            search
                                                            onAddItem={handleAdditionCc}
                                                            placeholder='Select an email'
                                                            value={value}
                                                            renderLabel={renderLabel}
                                                            error={error?.length && {
                                                                content: 'Please remove invalid emails',
                                                                pointing: 'below'
                                                            }}
                                                        />
                                                    )}
                                                />
                                            }
                                        />
                                        <ListItem
                                            className='EmailThread__Row--input'
                                            description={
                                                <Controller
                                                    name='bcc'
                                                    control={control}
                                                    render={({ field: { onChange, value, name }, fieldState: { error } }) =>
                                                        <Form.Dropdown
                                                            className='EmailThread__BCC'
                                                            label={<label><Icon name='user' color='black' /> Bcc</label>}
                                                            options={emailBccOptions}
                                                            onChange={(_, { value: selected }) => { onChange({ target: { name: name, value: selected } }) }}
                                                            selection
                                                            fluid
                                                            multiple
                                                            allowAdditions
                                                            search
                                                            onAddItem={handleAdditionBcc}
                                                            placeholder='Select an email'
                                                            value={value}
                                                            renderLabel={renderLabel}
                                                            error={error?.length && {
                                                                content: 'Please remove invalid emails',
                                                                pointing: 'below'
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <ListItem
                                            className='EmailThread__Row--input'
                                            description={
                                                <Controller
                                                    name='subject'
                                                    control={control}
                                                    render={({ field: { onChange, value, name }, fieldState: { error } }) =>
                                                        <Form.Input
                                                            width={5}
                                                            label={<label><Icon name='envelope' color='black' /> Subject</label>}
                                                            onChange={onChange}
                                                            name={name}
                                                            value={value}
                                                            error={error && error.message && {
                                                                content: error.message,
                                                                pointing: 'below'
                                                            }}
                                                            required
                                                            placeholder='Type a subject'
                                                        />
                                                    }
                                                />
                                            }
                                        />
                                        <ListItem
                                            className='EmailThread__Row--input'
                                            description={
                                                <Form.Field>
                                                    <Form.Field label={
                                                        <label>
                                                            <Icon name='fa-solid fa-pen' color='black' /> Signature {userSignature === null && companySignature === null && <Popup trigger={<Icon name='fa-solid fa-circle-info' color='blue' />} content='Signature needs to be configured' inverted basic position='right center' />}
                                                        </label>
                                                    } />
                                                    <Controller
                                                        name='signature'
                                                        control={control}
                                                        rules={{
                                                            required: {
                                                                message: 'This field is required',
                                                                value: true
                                                            }
                                                        }}
                                                        render={({ field: { onChange, value, name }, fieldState: { error } }) =>
                                                            <Form.Group style={{ marginTop: "0.75em" }}>
                                                                {SIGNATURE_OPTIONS.map((option, id) => (
                                                                    <Form.Radio
                                                                        key={option.key}
                                                                        label={<label style={{ paddingLeft: '1.4em' }}>{option.text}</label>}
                                                                        value={option.value}
                                                                        checked={value === option.value}
                                                                        disabled={isDisabledSignature[id]}
                                                                        onChange={() => { onChange({ target: { name: "signature_preference", value: option.value } }) }}
                                                                    />
                                                                ))}
                                                            </Form.Group>
                                                        }
                                                    />
                                                </Form.Field>
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <ListItem
                                            className='EmailThread__SimpleRow--input EmailBody'
                                            description={
                                                <Controller
                                                    name="body"
                                                    control={control}
                                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                        <Form.Field
                                                            className='EmailBody__RichTexEditor'
                                                            control={RichTextEditor}
                                                            basic
                                                            style={{ marginBottom: 0 }}
                                                            onChange={(_, { value: new_value }) => { onChange({ target: { name: 'body', value: new_value } }) }}
                                                            value={value}
                                                            toolbarClassName={toolbarClassName}
                                                            allowSwitchToHTML
                                                            onSwitchToHTML={() => { }}
                                                            onSwitchToRichText={() => { }}
                                                            mode={TEXT_EDITOR_MODES.RICH_TEXT}
                                                            editorRef={editorRef}
                                                            otherControls={[
                                                                <CategorizedTemplates
                                                                    onSelectTemplate={(templateId) => handleChangeTemplate(templateId, setValue, dispatch, trigger, userSignature, companySignature)}
                                                                    categorized_templates={categorized_templates}
                                                                    type='Email'
                                                                />,
                                                                <Popup
                                                                    trigger={
                                                                        <Button
                                                                            primary
                                                                            onClick={() => { setPersonalize(!isPersonalizing) }}
                                                                        >
                                                                            Personalize
                                                                        </Button>
                                                                    }
                                                                    open={isPersonalizing}
                                                                    onClose={() => { setPersonalize(false) }}
                                                                    position='bottom right'
                                                                    on='click'
                                                                    content={personalizeButtons(account_id, personalize)}
                                                                />
                                                            ]}
                                                            error={error && error.message && {
                                                                content: error.message,
                                                                pointing: 'below'
                                                            }}
                                                        />
                                                    )}
                                                />
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <ListItem
                                            className='EmailThread__SimpleRow--input Email_Attachment'
                                            description={
                                                <Controller
                                                    name="attachments"
                                                    control={control}
                                                    render={({ field: { onChange, value, name } , fieldState: { error }}) => (
                                                        <Form.Field
                                                            control={Input}
                                                            error={error && error.message && {
                                                                content: error.message,
                                                                pointing: 'below'
                                                            }}
                                                            className='Template__attachments-section'
                                                        >
                                                            <Segment style={{width: '100%'}}>
                                                            <div>
                                                                <Icon onClick={() => { dropzoneRef.current.open() }} color='blue' name='plus circle' link /> <b>Attach {value.length > 0 && 'more '}files</b>
                                                            </div>
                                                            <div className='Template__attachments'>
                                                                <FileInput
                                                                    onChange={(_, { value: new_value }) => {
                                                                        onChange({
                                                                            target: {
                                                                                name, value: [
                                                                                    ...value,
                                                                                    ...new_value.filter(new_file => (
                                                                                        value.findIndex(file => file.path === new_file.path) === -1
                                                                                    ))
                                                                                ]
                                                                            }
                                                                        })
                                                                    }}
                                                                    onReject={(rejected) => {
                                                                        if (rejected.length > 0) {
                                                                            Notification.alert("File type must be .pdf.", STATUS_TYPES.ERROR, true);
                                                                        }
                                                                    }}
                                                                    onRemove={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                                    files={value}
                                                                    name="files"
                                                                    accept="application/pdf"
                                                                    icon="file pdf outline"
                                                                    size="mini"
                                                                    dropzoneRef={dropzoneRef}
                                                                    showIcon
                                                                />
                                                            </div>
                                                            <i>Remaining: {FileUtils.getRemainingSize(value, FILE_CATEGORIES.EMAIL)} MB </i> {error?.message && `(${error.message})`}
                                                            </Segment>
                                                        </Form.Field>
                                                    )}
                                                />
                                            }
                                        />
                                    </Form.Group>
                                    <Grid.Column
                                        textAlign='left'
                                        className='EmailThread__ButtonsRow'
                                    >
                                        <Button
                                            disabled={!isDirty || !isValid || hasErrors || isSendingEmail}
                                            primary
                                            size='tiny'
                                            content='Send'
                                            loading={false}
                                            onClick={handleSend}
                                            style={{ marginRight: 10, width: '100px' }}
                                        />
                                        <Button
                                            basic
                                            size='tiny'
                                            content='Preview'
                                            loading={false}
                                            onClick={() => setIsPreviewModalOpen(true)}
                                            style={{ marginRight: 0, width: '100px', backgroundColor: 'white', fontWeight: '700' }}
                                        />
                                    </Grid.Column>
                                </Form>
                            </Container>
                        </Grid>
                    }
                />
            </Segment>
            {isPreviewModalOpen &&
                <PreviewEmailTemplateModal
                    open={isPreviewModalOpen}
                    onClose={() => setIsPreviewModalOpen(false)}
                    subject={getValues().subject}
                    content={getEmailPreview({ ...getValues() }, userSignature, companySignature, contactInfo, userProfile, contactLocation, account)}
                    attachments={getValues().attachments}
                />}
        </React.Fragment>
    )
};

export default FirstEmail;
