import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { Modal, ModulesFilter } from 'dyl-components';

import AddOpportunityModal from "shared/modals/AddOpportunityModal";

import {
    KEY_CREATED, KEY_CREATED_BY, KEY_OWNED_BY, KEY_LAST_MODIFIED, KEY_SOURCE, 
    KEY_LAST_MODIFIED_BY, KEY_CAMPAIGN_SOURCE, KEY_OPPORTUNITY_ID, 
    KEY_OPPORTUNITY_NAME, KEY_CONVERT, KEY_CONTACT_NAME, KEY_ACCOUNT, 
    KEY_ACTIONS, KEY_STAGE, KEY_OUTCOME, KEY_LOST_REASON
} from 'shared/constants/MODULE_COLUMNS';
import FullScreenModalPopUp from 'shared/FullScreenModalPopUp';

const COLUMN_ROWS = [
    [
        { value: KEY_CREATED, label: "Created" },
        { value: KEY_LOST_REASON, label: "Lost Reason/Type" },
        { value: KEY_CREATED_BY, label: "Created by" },
    ],
    [
        { value: KEY_OWNED_BY, label: "Owner" },
        { value: KEY_LAST_MODIFIED, label: "Last Modified" },
        { value: KEY_SOURCE, label: "Master/Secondary Source" },
    ],
    [
        { value: KEY_LAST_MODIFIED_BY, label: "Last Modified By" },
        { value: KEY_CAMPAIGN_SOURCE, label: "Campaign Converted" },
        { value: KEY_OPPORTUNITY_ID, label: "Opportunity ID", isForcedChecked: true },
    ],
    [
        { value: KEY_CONTACT_NAME, label: "Contact Name", isForcedChecked: true },
        { value: KEY_OPPORTUNITY_NAME, label: "Opportunity Name", isForcedChecked: true },
        { value: KEY_CONVERT, label: "Convert" }
    ],
    [
        { value: KEY_ACCOUNT, label: "Account" },
        { value: KEY_ACTIONS, label: "Actions" },
        { value: KEY_STAGE, label: "Opportunity Stage" },
    ],
    [
        { value: KEY_OUTCOME, label: "Outcome" },
    ],
];

const OpportunitiesToolbar = ({ search, onSearchSubmit, cancelFunction, onChangeSearch, isColumnHidden, onToggleColumn }) => {
    const { isReadingOpportunities, isReadingDisplay } = useSelector(state => ({
        isReadingOpportunities: state.opportunities.isReadingOpportunities
    }));

    const [isAddOpportunityModalOpen, setIsAddOpporunityModalOpen] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    const onOpenAddOpportunityModal = () => {
        setIsAddOpporunityModalOpen(true);
    }

    const onCloseAddOpportunityModal = () => {
        setIsAddOpporunityModalOpen(false);
    }

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row columns='equal' verticalAlign='middle'>
                    <Grid.Column width={5}>
                        <ModulesFilter
                            isLoading={isReadingOpportunities || isReadingDisplay}
                            search={search}
                            onSearchSubmit={onSearchSubmit}
                            cancelFunction={cancelFunction}
                            onChangeSearch={onChangeSearch}
                            columnRows={COLUMN_ROWS}
                            columnCount={3}
                            isColumnHidden={isColumnHidden}
                            onToggleColumn={onToggleColumn}
                        />
                    </Grid.Column>
                    <Grid.Column width={3} floated='right'>
                        <Button floated='right' className='ModulesListToolbar__NewModulesListButton' onClick={onOpenAddOpportunityModal}>
                            <Icon className='plus' /> New Opportunity
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Modal open={isAddOpportunityModalOpen} onClose={() => {
                isDirty
                    ? setIsConfirmationOpen(true)
                    : onCloseAddOpportunityModal();
            }}>
                <AddOpportunityModal onOpen={() => { return Promise.resolve(); }} onClose={onCloseAddOpportunityModal} onDirtyChange={(dirty) => setIsDirty(dirty)} />
            </Modal>
            <FullScreenModalPopUp
                header={"Changes not saved"}
                subheader={"Are you sure you want to exit?"}
                isOpen={isConfirmationOpen}
                onConfirm={() => {
                    onCloseAddOpportunityModal();
                    setIsConfirmationOpen(false);
                    setIsDirty(false)
                }}
                onFormClose={() => setIsConfirmationOpen(false)}
                closeIcon={false}
            />
        </React.Fragment>
    );
}

export default OpportunitiesToolbar;
