import userActions from "actions/user";
import { Table, Button, Person, TableLoader, STATUS_TYPES, Notification } from "dyl-components";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Header, Icon, Popup } from "semantic-ui-react";
import ResendEmailForm from "shared/forms/ResendEmailForm";

const Users = () => {
    const { summarized_users, isReadingSummarizedUsers } = useSelector(state => state.user);
    const isCheckingIfAccountHasOpenSeats = useSelector(state => state.subscription.isCheckingIfAccountHasOpenSeats);
    const [userToResendEmail, setUserToResendEmail] = useState(null);
    const { account_id } = useParams();
    const dispatch = useDispatch();
    const onResendEmail = async (email) => {
        try {
            await dispatch(userActions.resendEmail({ email }));
            Notification.alert(`Email sent to ${email}`, STATUS_TYPES.SUCCESS);
            setUserToResendEmail(null);
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to resend email", STATUS_TYPES.ERROR);
        }
    };
    const isResendingEmail = useSelector(state => state.user.isResendingEmail);
    
    return (
        <Table>
            <Table.Header primary>
                <Table.Row>
                    <Table.HeaderCell colspan={2}>
                        <Icon className="fas fa-user-lock" /> User Account
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {isReadingSummarizedUsers || isCheckingIfAccountHasOpenSeats ? (
                    <Table.Row>
                        <TableLoader isLoading colspan={2} />
                    </Table.Row>
                ) : summarized_users?.map(user => (
                    <Table.Row key={user.user_id}>
                        <Table.Cell>
                            <Person 
                                popup={false}
                                username={`${user.first_name} ${user.last_name} ${user.is_owner && `(Owner)`}`} 
                                email={user.email}
                            />
                        </Table.Cell>
                        <Table.Cell>
                            {!user.has_log_in ? (
                                <Popup 
                                    on='click'
                                    open={userToResendEmail === user.user_id}
                                    onOpen={() => setUserToResendEmail(user.user_id)}
                                    onClose={() => setUserToResendEmail(null)}
                                    trigger={<Button floated='right'>Resend Email</Button>} 
                                content={<>
                                    <Header color='primary'>Resend Email</Header>
                                    <ResendEmailForm email={user.email} idOfUserToUpdate={user.user_id} customer_id={account_id} onClose={() => setUserToResendEmail(null)} />
                                </>}
                                position="bottom center"
                            /> ) : (
                                <Button floated='right' disabled={isResendingEmail} loading={isResendingEmail} onClick={() => onResendEmail(user.email)}>Resend Email</Button>
                            )}
                            <Button floated='right' disabled>Simulate</Button>
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    )
}

const AdminSettings = ({ onGenerateAccount }) => {
    const { summarized_users, isReadingSummarizedUsers } = useSelector(state => state.user);
    const canAddUsers = useSelector(state => {
        return state.subscription.hasOpenSeats && !summarized_users?.length
    })
    const isCheckingIfAccountHasOpenSeats = useSelector(state => state.subscription.isCheckingIfAccountHasOpenSeats);
    return (
        <Grid>
            {!isReadingSummarizedUsers && !isCheckingIfAccountHasOpenSeats && canAddUsers && (
                <Grid.Row>
                    <Grid.Column>
                        <Button primary onClick={onGenerateAccount}>
                            Generate Account
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            )}
            <Grid.Row>
                <Grid.Column width={10}>
                    <Users />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default AdminSettings;
