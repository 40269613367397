import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function accountReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.ACCOUNT, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingAccount: true };
        case getNamedAction(ACTION_NAMES.ACCOUNT, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingAccount: false };
        case getNamedAction(ACTION_NAMES.ACCOUNT, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingAccount: false };

        case getNamedAction(ACTION_NAMES.ACCOUNT, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingAccounts: true };
        case getNamedAction(ACTION_NAMES.ACCOUNT, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingAccounts: false, accounts: action.data.data, accounts_count: action.data.count, counts: action.data.counts };
        case getNamedAction(ACTION_NAMES.ACCOUNT, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingAccounts: false, accounts: [], accounts_count: 0 };

        case getNamedAction(ACTION_NAMES.ACCOUNT, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingAccount: true };
        case getNamedAction(ACTION_NAMES.ACCOUNT, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingAccount: false };
        case getNamedAction(ACTION_NAMES.ACCOUNT, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingAccount: false };

        case getNamedAction(ACTION_NAMES.PAUSE_CUSTOMER, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, customerBeingPaused: action.id };
        case getNamedAction(ACTION_NAMES.PAUSE_CUSTOMER, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, customerBeingPaused: null };
        case getNamedAction(ACTION_NAMES.PAUSE_CUSTOMER, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, customerBeingPaused: null };

        case getNamedAction(ACTION_NAMES.CANCEL_CUSTOMER, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, customerBeingCanceled: action.id };
        case getNamedAction(ACTION_NAMES.CANCEL_CUSTOMER, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, customerBeingCanceled: null };
        case getNamedAction(ACTION_NAMES.CANCEL_CUSTOMER, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, customerBeingCanceled: null };

        case getNamedAction(ACTION_NAMES.ACCOUNT_LOCATION, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingLocation: true };
        case getNamedAction(ACTION_NAMES.ACCOUNT_LOCATION, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingLocation: false };
        case getNamedAction(ACTION_NAMES.ACCOUNT_LOCATION, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingLocation: false };

        case getNamedAction(ACTION_NAMES.ACCOUNT_LOCATION, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingLocation: true };
        case getNamedAction(ACTION_NAMES.ACCOUNT_LOCATION, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingLocation: false };
        case getNamedAction(ACTION_NAMES.ACCOUNT_LOCATION, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingLocation: false };

        case getNamedAction(ACTION_NAMES.ACCOUNT, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingAccount: true, account: null, accountError: false };
        case getNamedAction(ACTION_NAMES.ACCOUNT, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            {
                const { social_media, ...account } = action.data;
                return {
                    ...state, isReadingAccount: false,
                    account,
                    social_media: (social_media || { data: [] }).data
                }
            }
        case getNamedAction(ACTION_NAMES.ACCOUNT, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingAccount: false, accountError: true };

        case getNamedAction(ACTION_NAMES.ACCOUNT_OPPORTUNITIES, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingOpportunities: true, opportunities: [], opportunity_count: 0 };
        case getNamedAction(ACTION_NAMES.ACCOUNT_OPPORTUNITIES, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return {
                ...state, isReadingOpportunities: false,
                opportunities: action.data.data || [],
                opportunity_count: action.data.count || 0,
                opportunityQueryParameters: action.queryParameters,
                opportunity_stages: action.data.pipeline_stages ? action.data.pipeline_stages.stages : []
            };
        case getNamedAction(ACTION_NAMES.ACCOUNT_OPPORTUNITIES, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingOpportunities: false, opportunities: [], opportunity_count: 0 };

        case getNamedAction(ACTION_NAMES.ACCOUNT_CONTACTS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingContacts: true, contacts: [] };
        case getNamedAction(ACTION_NAMES.ACCOUNT_CONTACTS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingContacts: false, contacts: action.data.data, contacts_count: action.data.count };
        case getNamedAction(ACTION_NAMES.ACCOUNT_CONTACTS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingContacts: false, contacts: [] };

        case getNamedAction(ACTION_NAMES.ACCOUNT_CONTACTS_PIN_OPTIONS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingContactsForPinning: true, contactsForPinning: [] };
        case getNamedAction(ACTION_NAMES.ACCOUNT_CONTACTS_PIN_OPTIONS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingContactsForPinning: false, contactsForPinning: action.data.data };
        case getNamedAction(ACTION_NAMES.ACCOUNT_CONTACTS_PIN_OPTIONS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingContactsForPinning: false, contactsForPinning: [] };

        case getNamedAction(ACTION_NAMES.LINK_CONTACTS_TO_ACCOUNT, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isLinkingContactsToAccount: true };
        case getNamedAction(ACTION_NAMES.LINK_CONTACTS_TO_ACCOUNT, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isLinkingContactsToAccount: false };
        case getNamedAction(ACTION_NAMES.LINK_CONTACTS_TO_ACCOUNT, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isLinkingContactsToAccount: false };

        case getNamedAction(ACTION_NAMES.ACCOUNT_CONTACT_IDS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingContactIds: true, contactIds: [] };
        case getNamedAction(ACTION_NAMES.ACCOUNT_CONTACT_IDS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingContactIds: false, contactIds: action.data || [] };
        case getNamedAction(ACTION_NAMES.ACCOUNT_CONTACT_IDS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingContactIds: false, contactIds: [] };

        case 'CLEAR_ACCOUNT_CONTACT_IDS':
            return { ...state, isReadingContactIds: false, contactIds: [] };

        case getNamedAction(ACTION_NAMES.FUND_TYPE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingFundType: true };
        case getNamedAction(ACTION_NAMES.FUND_TYPE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingFundType: false };
        case getNamedAction(ACTION_NAMES.FUND_TYPE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingFundType: false };

        case 'INLINE_UPDATE_ACCOUNT_CUSTOM_DATA':
            return { ...state, account: { ...state.account, custom_data: { ...state.account.custom_data, children: action.groups } } }

        default:
            return state;
    }
}


export default accountReducer;
