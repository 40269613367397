import types from "actions/office_view/types";
import callTypes from "actions/call/types";
import initialState from "./state";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

const newCall = (state, leg, call) => {
    call.status = "ringing";
    call.display = true;
    call.timeTicker = 0;
    call.duration = 0;
    const userLegs = call.legs.filter(l => !!l.extension);
    userLegs?.forEach(userLeg => {
        // TODO: Call queue, Parking lot or user extension, Verify the extension is a extension that shows up on the view, may be able to add on PBX worker
        if (!state.currentCall && userLeg.extension === state.creds.extension) {
            state.currentCall = call.uuid
        }
        state.calls[call.uuid] = call
        if (state.calls['_' + call.sip_call_id]) {
            delete state.calls['_' + call.sip_call_id]
            if (state.currentCall === '_' + call.sip_call_id) {
                state.currentCall = call.uuid
            }
            const idx = state.extensions[state.creds.extension].indexOf('_' + call.sip_call_id)
            if (idx !== -1) {
                state.extensions[state.creds.extension][idx] = call.uuid
            } else {
                state.extensions[state.creds.extension].push(call.uuid)
            }
        } else if (state.extensions[userLeg.extension]) {
            //if call extension exisits
            state.extensions[userLeg.extension].push(call.uuid)
        } else {
            //if extension does not exisit
            state.extensions[userLeg.extension] = [call.uuid];
        }
    })
    return { ...state, extensions: { ...state.extensions }, calls: { ...state.calls }} ;
}

const connectedCall = (state, leg, call) => {
    call.status = "active";
    if (state.calls[call.uuid]) {
        //if WS is avalible update
        state.calls[call.uuid].connected = call.connected;
        state.calls[call.uuid].status = "active";
    } else if (state.calls['_' + call.sip_call_id]) {
        //if WEBRTC is avalible update
        state.calls[call.uuid] = call;
        delete state.calls['_' + call.sip_call_id]
        if (state.currentCall === '_' + call.sip_call_id) {
            state.currentCall = call.uuid;
        }
        const idx = state.extensions[state.creds.extension].indexOf('_' + call.sip_call_id)
        if (idx !== -1) {
            state.extensions[state.creds.extension][idx] = call.uuid
        }
    } else {
        //set WS message
        state.calls[call.uuid] = call;
    }
    return state;
};

const endCall = (state, legUUID, call) => {
    if (state.currentCall === call.uuid) {
        state.calls[call.uuid].status = state.isIgnore ? "ignore" : "hangup";
    } 
    //Does not seem to work
    // else if (state.calls[call.uuid]?.call_type !== "dialer") {
    //     //TODO:
    //     // When dialer if the contact leg hangups the agent is still on the call so we do not remove it.
    //     // Instead we need to listen for call result or a refresh or something
        // delete state.calls[call.uuid]
    //     // delete state.currentCall
    // }

    //Removes call
    const leg = call.legs.find(l => l.channel_uuid === legUUID && !!l.extension);
    if(leg){
        state.extensions[leg.extension] = state.extensions[leg.extension]?.filter(c => c !== call.uuid)
        state.calls[call.uuid].duration = call?.duration;
    }
    return { ...state, currentCallRecording: call.uuid, extensions: { ...state.extensions }, calls: { ...state.calls }};
};

function officeViewReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.OFFICE_VIEW, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingOfficeView: true };
        case getNamedAction(ACTION_NAMES.OFFICE_VIEW, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {
                ...state,
                isReadingOfficeView: false,
                numberOfConferenceRooms: action.data.conference_rooms,
                departments: action.data.departments,
                extensions: action.data.extensions,
                queues: action.data.queues
            };
        case getNamedAction(ACTION_NAMES.OFFICE_VIEW, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingOfficeView: false };

        case getNamedAction(ACTION_NAMES.CALLS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCalls: true };
        case getNamedAction(ACTION_NAMES.CALLS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCalls: false, calls: action.data };
        case getNamedAction(ACTION_NAMES.CALLS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCalls: false };

        case getNamedAction(ACTION_NAMES.RECENT_CALLS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingRecentCalls: true };
        case getNamedAction(ACTION_NAMES.RECENT_CALLS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingRecentCalls: false, recentCalls: action.data };
        case getNamedAction(ACTION_NAMES.RECENT_CALLS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingRecentCalls: false };

        case types.ASSIGN_INCOMING_CALL_TO_QUEUE:
            return {
                ...state,
                queues: action.queues,
                incomingCalls: action.incomingCalls
            }
        case types.ASSIGN_INCOMING_CALL_TO_DEPARTMENT_AGENT:
            return {
                ...state,
                extensions: action.extensions,
                incomingCalls: action.incomingCalls
            }
        case types.ASSIGN_QUEUED_CALL_TO_DEPARTMENT_AGENT:
            return {
                ...state,
                extensions: action.extensions,
                queues: action.queues
            }

        case types.CALL_REQUEST:
            return { ...state, isCalling: true }
        case types.CALL_SUCCESS:
            return {
                ...state,
                incomingCalls: action.incomingCalls,
                callerInActionsView: action.contact || {},
                isActiveCall: true,
                actionsViewVisible: true,
                isCalling: true
            };
        case types.CALL_FAILURE:
            return { ...state, isCalling: false }

        case types.CLICK_RECENT_CALL:
            return {
                ...state,
                callerInActionsView: action.contact,
                actionsViewVisible: true,
                isActiveCall: false
            }

        case types.CLOSE_ACTIONS_VIEW:
            return { ...state, actionsViewVisible: false }

        case types.CLICK_ACTIVE_CALLER:
            return {
                ...state,
                callerInActionsView: action.contact,
                isActiveCall: true,
                actionsViewVisible: true
            }

        case types.REORDER_CALL_QUEUES:
            return {
                ...state,
                queues: action.queues
            }

        case types.HANGUP:
            return {
                ...state,
                isActiveCall: false,
                actionsViewVisible: false,
                callerInActionsView: null
            }

        case types.CHANGE_TEAMS_TAB:
            return {
                ...state,
                selectedTeamsTab: action.teamsTab
            }
            
        case callTypes.NEW_CALL:
            return newCall({ ...state }, action.leg, action.call)
        case callTypes.END_CALL:
            return endCall({ ...state }, action.leg, action.call)
        case callTypes.CONNECT_CALL:
            return connectedCall({ ...state }, action.leg, action.call)

        case getNamedAction(ACTION_NAMES.SOFTPHONE_CREDS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCreds: true };
        case getNamedAction(ACTION_NAMES.SOFTPHONE_CREDS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCreds: false, creds: action.data };
        case getNamedAction(ACTION_NAMES.SOFTPHONE_CREDS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCreds: false, creds: {} };

        case 'RINGING_CALL':
            return { ...state, callState: 'ringing' };
        case 'ACTIVE_CALL':
            return { ...state, callState: 'active' };
        case 'IDLE_CALL':
            return { ...state, callState: 'idle' };
        case 'HANGUP_CALL':
            return { ...state, callState: 'hangup', currentCall: null, callDirection: null, isIgnore: false };
        case 'IGNORE_CALL':
            return { ...state, callState: 'idle', currentCall: null, callDirection: null, isIgnore: false };
        case 'CALL_DIRECTION':
            return { ...state, callDirection: action.direction };
        case 'CURRENT_CALL':
            return { ...state, currentCall: action.currentCall };
        case 'CALL_DURATION':
            return { ...state, duration: action.duration };
        case 'CALL_IGNORE':
            return { ...state, isIgnore: action.isIgnore };
        case 'CALL_CNAM':
            return { ...state, cnam: action.cnam };
        case 'CALL_ACTION_BUTTONS':
            return { ...state, actionButtons: {...state.actionButtons, ...action.actionButton}};
        case 'CALL_RECORDING':
            const recordings = action.callRecordings;
            for (const key in recordings) {
                if (state.calls.hasOwnProperty(recordings[key].call_uuid)) {
                    state.calls[recordings[key].call_uuid].callRecording = recordings[key]
                }
            }  
            return { ...state, calls: { ...state.calls }};
        case 'CALL_CURRENT_NUMBER':                            
            return { ...state, currentCallPhoneNumber: action.currentCallPhoneNumber };
        case 'SET_SELECTED_TAB':                            
            return { ...state, selectedTab: action.selectedTab };
        case 'CALL_CURRENT_TICKER':                          
            if(state.calls[action?.uuid]){
                state.calls[action?.uuid].timeTicker = state.calls[action?.uuid].timeTicker + 1;
            }
            return { ...state, calls: { ...state.calls }}; 
        case 'CLOSE_CALL_NOTIFICATIONS': 
            const userPhoneCreds = state.creds.extension;
            const calls = state.calls;
            if(action?.call && state.calls[action?.call]){
                state.calls[action.call].display = false;
            } else {
                for (const key in calls) {
                    if (calls.hasOwnProperty(key)) {
                        if (state.extensions[userPhoneCreds]?.includes(key)) {
                            state.calls[key].display = false;
                        }
                    }
                }   
            }
            return { ...state, calls: { ...state.calls }}

        case getNamedAction(ACTION_NAMES.HANGUP, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isHangingUp: true };
        case getNamedAction(ACTION_NAMES.HANGUP, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isHangingUp: false };
        case getNamedAction(ACTION_NAMES.HANGUP, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isHangingUp: false };

        default:
            return state;
    }
}


export default officeViewReducer;
