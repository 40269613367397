import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function sendEmailReducer(state = initialState, action) {
    const { isReadingContactLocations, location, contact: contactFromModal, email: emailFromModal, phone: phoneFromModal, isReadingContact } = state.modal;
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.MODAL_SEND_EMAIL_CONTACT, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, modal: {isReadingContactLocations, location, isReadingContact: true} }
        case getNamedAction(ACTION_NAMES.MODAL_SEND_EMAIL_CONTACT, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            const modalContact = action.data.contact;
            const { email: modalEmail, phone: modalPhone, ...rest } = modalContact;
            return {
                ...state,
                modal: {
                    isReadingContactLocations,
                    location,
                    contact: rest,
                    email: modalEmail?.data || [],
                    phone: modalPhone?.data || [],
                    isReadingContact: false
                }
            }
        case getNamedAction(ACTION_NAMES.MODAL_SEND_EMAIL_CONTACT, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, modal: { isReadingContactLocations, location, isReadingContact: false, contact: {}, email: [], phone: []} }
        case getNamedAction(ACTION_NAMES.MODAL_SEND_EMAIL_LOCATION, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, modal: { contact: contactFromModal, email: emailFromModal, phone: phoneFromModal, isReadingContact, isReadingContactLocations: true} }
        case getNamedAction(ACTION_NAMES.MODAL_SEND_EMAIL_LOCATION, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, modal: { contact: contactFromModal, email: emailFromModal, phone: phoneFromModal, isReadingContact, location: action.data?.data || [], isReadingContactLocations: false} }
        case getNamedAction(ACTION_NAMES.MODAL_SEND_EMAIL_LOCATION, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, modal: { contact: contactFromModal, email: emailFromModal, phone: phoneFromModal, isReadingContact, isReadingContactLocations: false, location: [] } }

        case getNamedAction(ACTION_NAMES.SEND_EMAIL_CONTACT, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingContact: true }
        case getNamedAction(ACTION_NAMES.SEND_EMAIL_CONTACT, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            const { contact } = action.data;
            const { email, phone, first_name, last_name, suffix, primary_location_id } = contact;

            return {
                ...state,
                contact: { first_name, last_name, suffix, primary_location_id },
                email: (email || { data: [] }).data,
                phone: (phone || { data: [] }).data,
                isReadingContact: false
            }
        case getNamedAction(ACTION_NAMES.SEND_EMAIL_CONTACT, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingContact: false, contact: {}, email: [], phone: [] }

        case getNamedAction(ACTION_NAMES.SEND_EMAIL_LOCATION, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingContactLocations: true }
        case getNamedAction(ACTION_NAMES.SEND_EMAIL_LOCATION, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state,  location: action.data?.data || [], isReadingContactLocations: false }
        case getNamedAction(ACTION_NAMES.SEND_EMAIL_LOCATION, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingContactLocations: false, location: [] }

        default:
            return state;
    }
}

export default sendEmailReducer;
