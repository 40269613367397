import React, { useEffect } from 'react';
import { Modal } from 'dyl-components';
import { Grid, Loader } from 'semantic-ui-react';
import FirstEmail from 'shared/EmailTab/FirstEmail';
import oauthActions from 'actions/oauth';
import { useDispatch, useSelector } from 'react-redux';
import sendEmailActions from 'actions/send_email';
import accountActions from 'actions/account';

import './index.scss';

const SendEmailModal = ({
    open,
    onClose,
    contact_id,
    account_id,
    isAccountModal = false,
}) => {
    const close = () => {
        onClose();
    }
    const sendEmailReducer = useSelector(state => state.send_email.modal);
    const isSendingEmail = useSelector(state => state.email.isSendingEmail);

    const { isReadingContact, isReadingContactLocations } = sendEmailReducer;
    const account = useSelector(state => state.account);

    useEffect(()=>{
        if(isSendingEmail){
            onClose();
        }
    }, [isSendingEmail, onClose])

    const dispatch = useDispatch();

    useEffect(() => {
        if (contact_id) {
            dispatch(sendEmailActions.readModalContact(contact_id));
            dispatch(sendEmailActions.getModalLocations(contact_id));
        }
    }, [contact_id, dispatch]);

    useEffect(() => {
        if(account_id){
            dispatch(accountActions.readAccount(account_id));
            dispatch(accountActions.readContactsForPinning(Number(account_id), { page: 1, limit: 100 }))
        }
        dispatch(oauthActions.getIntegrations({ scopes: 'email' }));
    }, [dispatch, account_id]);

    return (
        <Modal open={open} onClose={close}>
            <Modal.Header>Send Email</Modal.Header>
            <Modal.Content>
                <Grid className='EmailTab'>
                    <Grid.Column className="EmailTab__EmailForm">
                        {
                            isReadingContact || isReadingContactLocations ?
                                <Loader active /> :
                                <FirstEmail
                                    toggleSidePanel={() => { }}
                                    isSidePanelOpen={false}
                                    readEmails={() => { }}
                                    account={account}
                                    accountId={account?.account?.id}
                                    isAccountModal={isAccountModal}
                                    contact_id={contact_id}
                                    isModal={true}
                                    toolbarClassName={"modal_toolbar"}
                                    hideToggle
                                    hideTitleBorder
                                />
                        }
                    </Grid.Column>
                </Grid>
            </Modal.Content>
        </Modal>
    );
};

export default SendEmailModal;
