import { NestedDropdown } from "dyl-components";
import React from "react";
import { Controller } from "react-hook-form";
import { Form, Icon } from "semantic-ui-react";

const RouteCustomizationForm = ({
    destinations,
    onSave,
    onCancel,
    control,
    handleSubmit,
    isDirty,
    isValid
}) => {
    return (
        <div style={{ display: "flex", paddingBottom: 150 }}>
            <Controller
                name="routing"
                control={control}
                style={{ margin: 0 }}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => {
                    const [parent_value, child_value] = (value || "").split(
                        "/"
                    );
                    return (
                        <Form.Field
                            parent_value={parent_value}
                            child_value={child_value}
                            control={NestedDropdown}
                            value={value}
                            nested_options={destinations}
                            onChange={(_, { parent_value, child_value }) => {
                                onChange({ target: { name, value: parent_value && child_value ? `${parent_value}/${child_value}` : null } });
                            }}
                            placeholder="Select Destination"
                            selection
                            display_parent
                            style={{ width: "10em" }}
                            error={error && error?.message && ({
                                content: error.message,
                            })}
                        />
                    );
                }}
            />
            <div className="RouteCustomizationForm__buttonContainer">
                <Icon
                    size="large"
                    link
                    onClick={onCancel}
                    className="fa-solid fa-xmark redIcon"
                    style={{ marginBottom: 21 }}
                />
                <Icon
                    size="large"
                    disabled={!isDirty || !isValid}
                    color="primary"
                    link
                    onClick={handleSubmit(onSave)}
                    className="fa-solid fa-check"
                    style={{ marginBottom: 21 }}
                />
            </div>
        </div>
    );
};

export default RouteCustomizationForm;
