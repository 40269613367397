import { Grid, Header, Segment } from "semantic-ui-react";
import QuoteItemsTable from "shared/forms/Quote/QuoteItemsTable";
import OrderInformation from "./OrderInformation";
import RecurringProducts from "./RecurringProducts";
import QuoteTotals from "../Quote/QuoteTotals";
import { useController, useFieldArray, useFormContext } from "react-hook-form";
import SubscriptionTotals from "pages/SubscriptionProducts/SubscriptionTotals";
import { useSelector } from "react-redux";
import { MathUtils } from "utils";

const OrderSummary = ({ quote, account_id, disabled }) => {
    const { control } = useFormContext();

    const { field: cartField } = useController({
        name: "cart",
        control
    })

    const { fields: cart } = useFieldArray({
        control,
        name: "cart",
        keyName: "key",
    });

    const { field: orderStatusField } = useController({
        name: "order_status",
        control,
    });

    const isDeletingOrder = orderStatusField.value === "deleted";
    const { fund_type } = useSelector(
        (state) => state.order.order?.account || {}
    );
    const isDemoAccount = fund_type === "demo";
    const {
        subtotal,
        total,
        tax,
        discount,
        fee,
        next_invoice_date
    } = useSelector(state => {
        const order_status = state.order.order?.order_status;
        const next_invoice = state.subscription.next_invoice;
        const next_invoice_date = next_invoice?.next_invoice_date || null;
    
        if (isDemoAccount) {
            return {
                subtotal: 0,
                total: 0,
                tax: 0,
                discount: 0,
                fee: 0,
                next_invoice_date
            };
        }
    
        if (order_status && (order_status === "draft" || order_status === "open")) {
            const { grossTotal, totalDiscount, subtotal } =
                cartField.value
                    .filter(item => !Boolean(item.removed) && item.pricing_schedule)
                    .reduce(
                        (a, item) => {
                            const productDetails = quote.quote_summary.find(
                                (quoteItem) =>
                                    quoteItem.product_id === item.id &&
                                    quoteItem.product_variation_id === item.variation_id
                            );
                            const price = (() => {
                                const isOneTime =
                                    !productDetails.price_data?.model?.includes("recurring");
                                const pricingModel = productDetails.price_data.model;
                                if (isOneTime) {
                                    if (!pricingModel.includes("volume")) {
                                        return pricingModel === "usage"
                                            ? productDetails.price_data.price.price
                                            : productDetails.price_data.price;
                                    }
                                    return (
                                        productDetails.price_data.price.find(
                                            (range) =>
                                                item.quantity >= range.start &&
                                                item.quantity <= (range.end || Number.POSITIVE_INFINITY)
                                        )?.price || 0
                                    );
                                }
                                if (!pricingModel.includes("volume")) {
                                    return (
                                        productDetails.price_data.price[item.pricing_schedule]?.price || 0
                                    );
                                }
                                const frequency =
                                    productDetails.price_data.price[item.pricing_schedule]?.volumes || [];
                                return (
                                    frequency.find(
                                        (range) =>
                                            item.quantity >= range.start &&
                                            item.quantity <= (range.end || Number.POSITIVE_INFINITY)
                                    )?.price || 0
                                );
                            })();
    
                            const { grossTotal, subtotal, discountAmount } =
                                MathUtils.calculateItemTotals({
                                    price,
                                    additional_price: productDetails.product_additional_price || 0,
                                    quantity: item.quantity,
                                    discount: item.discount || 0,
                                    addons: [],
                                    taxes: [],
                                    fees: [],
                                });
    
                            return {
                                grossTotal: a.grossTotal + grossTotal,
                                totalDiscount: a.totalDiscount + discountAmount,
                                subtotal: a.subtotal + subtotal,
                            };
                        },
                        {
                            grossTotal: 0,
                            totalDiscount: 0,
                            subtotal: 0,
                        }
                    );
    
            return {
                subtotal: (next_invoice?.subtotal || 0) + subtotal,
                total: (next_invoice?.total || 0) + grossTotal,
                tax: next_invoice?.tax || 0,
                discount: (next_invoice?.discount || 0) + totalDiscount,
                fee: next_invoice?.fee || 0,
                next_invoice_date
            };
        }
    
        return state.order.order?.next_invoice || {
            subtotal: 0,
            total: 0,
            tax: 0,
            discount: 0,
            fee: 0,
            next_invoice_date
        };
    });
    

    const isReadingProducts = useSelector(
        (state) => state.subscription.isReadingCurrentProducts
    );

    const isReadingNextInvoice = useSelector(state => state.subscription.isReadingNextInvoice);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header color="primary">Order Information</Header>
                    <OrderInformation disabled={disabled} account_id={account_id} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column></Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Header color="primary">Order Summary</Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <QuoteItemsTable disabled={disabled || isDeletingOrder} quote={quote} cart={cart} isOrder />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Header color="primary">Recurring Products</Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Segment loading={isReadingProducts} basic style={{ padding: 0 }}>
                        <RecurringProducts cart={cartField.value} />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
                <Grid.Column />
                <Grid.Column floated="right">
                    <Segment>
                        <QuoteTotals isInvoice quote={quote} cart={cartField.value} />
                    </Segment>
                </Grid.Column>
                <Grid.Column floated="right">
                    <Segment loading={isReadingNextInvoice}>
                        <SubscriptionTotals
                            subtotal={subtotal || 0}
                            total={total || 0}
                            totalDiscount={discount || 0}
                            totalFee={fee || 0}
                            totalTax={tax || 0}
                            nextInvoiceDate={next_invoice_date}
                        />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default OrderSummary;
