import React, { useContext, useRef, useState } from 'react';
import { Header, Button, Dropdown, Icon, Ref } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, TableLoader, EllipsisDropdown, Person, DateTimeUtils, EllipsisDropdownPortal } from 'dyl-components';
import masterAccountActions from 'actions/master_account';
import { StringUtils } from 'utils';
import {
    KEY_CREATED_ON, KEY_ACCOUNT_TYPE, KEY_OWNER,
    KEY_ACCOUNT_STATUS, KEY_ACTIONS,
    KEY_REACTIVATED_ON, KEY_CREATED_BY, KEY_REACTIVATED_BY,
    KEY_LAST_MODIFIED, KEY_FUND_TYPE,
    KEY_LAST_MODIFIED_BY, KEY_CUSTOMER_SINCE,
    KEY_CANCELED_ON, KEY_CANCELED_BY
} from './constants';
import { StatusDropdown } from './ConversionControls';
import { FormsContext } from './FormsProvider';
import Account from 'shared/Account';

const DropdownItem = ({ text, icon, ...otherProps }) => {
    return (
        <Dropdown.Item text={<React.Fragment>
            <Icon name={icon} />
            {text}
        </React.Fragment>}
            style={{ cursor: 'pointer' }}
            {...otherProps}
        />
    );
}

const createActionOptions = (
    isNested,
    account_type,
    onLinkSelect,
    onUnlinkSelect,
    onDeleteMasterAccountSelect
) => {
    const options = [];

    switch (account_type) {
        case 'Master account':
            options.push({
                text: 'Delete',
                icon: 'fa-solid fa-trash',
                onClick: onDeleteMasterAccountSelect
            });
            break;
        case 'business':
            if (!isNested) {
                options.push({
                    text: 'Link Master Account',
                    icon: 'fas fa-diagram-nested',
                    onClick: onLinkSelect
                });
            } else {
                options.push({
                    text: 'Change Master Account',
                    icon: 'fa-solid fa-buildings',
                    onClick: onLinkSelect
                });
                options.push({
                    text: 'Unlink Master Account',
                    icon: 'fas fa-diagram-nested',
                    onClick: onUnlinkSelect
                });
            }
            break;
        default:
    }

    return options;
}

const Subrow = ({
    master_account,
    nested_account,
    isColumnHidden,
    getAccountStatus,

    onLinkSelect,
    onUnlinkSelect
}) => {
    const actionPopupRef = React.createRef();

    return (
        <Table.CollapsibleRowContent>
            <Table.Cell width={1} />
            {
                !isColumnHidden(KEY_CREATED_ON) &&
                <Table.Cell>
                    <div><b>{DateTimeUtils.formatEpoch(nested_account.created, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                    <div>{DateTimeUtils.formatEpoch(nested_account.created, DateTimeUtils.TIME_FORMAT)}</div>
                </Table.Cell>
            }
            {
                !isColumnHidden(KEY_CREATED_BY) &&
                <Table.Cell>{nested_account?.created_by}</Table.Cell>
            }
            {
                !isColumnHidden(KEY_LAST_MODIFIED) &&
                <Table.Cell>
                    <div><b>{nested_account.activity && DateTimeUtils.formatEpoch(nested_account.activity, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                    <div>{nested_account.activity && DateTimeUtils.formatEpoch(nested_account.activity, DateTimeUtils.TIME_FORMAT)}</div>
                </Table.Cell>
            }
            {
                !isColumnHidden(KEY_LAST_MODIFIED_BY) &&
                <Table.Cell>{nested_account.last_modified_by}</Table.Cell>
            }
            <Table.Cell>
                <Account
                    name={nested_account.name}
                    id={nested_account.id && `${nested_account.id}`}
                    linkToAccount={<Link to={`/account/${nested_account.id}`}>{nested_account.name}</Link>}
                    isActionsVisible
                    account={nested_account}
                />
            </Table.Cell>
            {
                !isColumnHidden(KEY_ACCOUNT_TYPE) &&
                <Table.Cell>
                    <b>{StringUtils.capitalize(nested_account.account_type)}</b>
                </Table.Cell>
            }
            {
                !isColumnHidden(KEY_OWNER) &&
                <Table.Cell>
                    {nested_account.owner && <Person username={nested_account.owner.name} email={nested_account.owner.email} />}
                </Table.Cell>
            }
            {
                !isColumnHidden(KEY_FUND_TYPE) && (
                    <Table.Cell>
                        <Header as='h5'>
                            {nested_account.stage?.name}
                            <Header.Subheader>
                                {nested_account.stage?.category_name}
                            </Header.Subheader>
                        </Header>
                    </Table.Cell>
                )
            }
            {!isColumnHidden(KEY_CUSTOMER_SINCE) && (
                <Table.Cell>
                    <div><b>{nested_account?.customer?.customer_since && DateTimeUtils.formatEpoch(nested_account?.customer?.customer_since, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                    <div>{nested_account?.customer?.customer_since && DateTimeUtils.formatEpoch(nested_account?.customer?.customer_since, DateTimeUtils.TIME_FORMAT)}</div>
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_CANCELED_ON) && (
                <Table.Cell>
                    <div><b>{nested_account.customer.canceled_on && DateTimeUtils.formatEpoch(nested_account.customer.canceled_on, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                    <div>{nested_account.customer.canceled_on && DateTimeUtils.formatEpoch(nested_account.customer.canceled_on, DateTimeUtils.TIME_FORMAT)}</div>
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_CANCELED_BY) && (
                <Table.Cell>{nested_account.customer.canceled_by}</Table.Cell>
            )}
            {!isColumnHidden(KEY_REACTIVATED_ON) && (
                <Table.Cell>
                    <div><b>{nested_account.customer.reactivated_on && DateTimeUtils.formatEpoch(nested_account.customer.reactivated_on, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                    <div>{nested_account.customer.reactivated_on && DateTimeUtils.formatEpoch(nested_account.customer.reactivated_on, DateTimeUtils.TIME_FORMAT)}</div>
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_REACTIVATED_BY) && (
                <Table.Cell>{nested_account.customer.reactivated_by}</Table.Cell>
            )}
            {!isColumnHidden(KEY_ACCOUNT_STATUS) &&
                <Table.Cell className='Account__status'>
                    {getAccountStatus({
                        id: nested_account.id,
                        account_type: nested_account.account_type,
                        account_status: nested_account.customer?.customer_status,
                        ...(nested_account.primary_contact.id && { primary_person_id: nested_account.primary_contact?.id })
                    })}
                </Table.Cell>
            }
            {
                !isColumnHidden(KEY_ACTIONS) &&
                <Table.Cell>
                    <EllipsisDropdown>
                        {createActionOptions(
                            true,
                            nested_account.account_type,
                            () => { onLinkSelect(nested_account.id, master_account.id); },
                            () => {
                                onUnlinkSelect(
                                    actionPopupRef,
                                    { master_account: master_account.id, account_id: nested_account.id }
                                );
                            }
                        ).map(({ text, icon, ...otherProps }, idx) =>
                            <Ref innerRef={actionPopupRef}>
                                <DropdownItem
                                    key={idx}
                                    text={text}
                                    icon={icon}
                                    {...otherProps}
                                />
                            </Ref>
                        )}
                    </EllipsisDropdown>
                </Table.Cell>
            }
        </Table.CollapsibleRowContent >
    )
}


export const AccountsRow = ({ account, isColumnHidden }) => {
    const actionPopupRef = React.createRef();
    const dispatch = useDispatch();
    const ref = useRef();
    const isDyl = useSelector(state => state.auth.dyl_employee);

    const { onStatusDropdownSelect, onLinkSelect, onUnlinkSelect, onDeleteMasterAccountSelect } = useContext(FormsContext);

    const getAccountStatus = (account) => {
        const isMasterAccount = account.account_type === 'Master account';
        if (isMasterAccount) {
            return (
                <Button size='tiny' className='Accounts__hasAccountsButton' onClick={account.business_count ? onToggle : null}>
                    {`${account.account_status} Account${parseInt(account.account_status) !== 1 ? 's' : ''}`}
                </Button>
            )
        }

        return (
            account.fund_type !== "unfunded" && <StatusDropdown
                account={account}
                onStatusDropdownSelect={onStatusDropdownSelect}
            />
        )
    }

    const [collapsed, setCollapsed] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const [isReadingAccounts, setIsReadingAccounts] = useState(false);

    const refreshAccounts = async () => {
        await setIsReadingAccounts(true);
        try {
            const response = await dispatch(masterAccountActions.readAccounts(account.id));
            await setAccounts(response.data);
        } catch (e) {
            console.log(e);
            await setAccounts([]);
        } finally {
            await setIsReadingAccounts(false);
        }
    }

    const hasNestedAccounts = account.account_type === 'Master account';
    const RowComponent = hasNestedAccounts ? Table.CollapsibleRow : Table.Row;

    const onToggle = () => {
        setCollapsed(!collapsed);
        if (!collapsed) {
            setAccounts([]);
        } else {
            refreshAccounts();
        }
    }

    return (
        <RowComponent
            onToggle={onToggle}
            collapsed={collapsed}
            toggleDisabled={!(Number(account.account_status) > 0)}
            subrowContent={(
                isReadingAccounts ? <TableLoader colspan={7} isLoading /> :
                    accounts?.map(nested_account =>
                        <Subrow
                            master_account={account}
                            nested_account={nested_account}
                            getAccountStatus={getAccountStatus}
                            isColumnHidden={isColumnHidden}
                            isReadingAccounts={isReadingAccounts}

                            onLinkSelect={onLinkSelect}
                            onUnlinkSelect={onUnlinkSelect}
                        />
                    )
            )}
        >
            {!hasNestedAccounts && <Table.Cell />}
            {
                !isColumnHidden(KEY_CREATED_ON) &&
                <Table.Cell>
                    <div><b>{DateTimeUtils.formatEpoch(account.created, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                    <div>{DateTimeUtils.formatEpoch(account.created, DateTimeUtils.TIME_FORMAT)}</div>
                </Table.Cell>
            }
            {
                !isColumnHidden(KEY_CREATED_BY) &&
                <Table.Cell>{account?.created_by}</Table.Cell>
            }
            {
                !isColumnHidden(KEY_LAST_MODIFIED) &&
                <Table.Cell>
                    <div><b>{account.activity && DateTimeUtils.formatEpoch(account.activity, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                    <div>{account.activity && DateTimeUtils.formatEpoch(account.activity, DateTimeUtils.TIME_FORMAT)}</div>
                </Table.Cell>
            }
            {
                !isColumnHidden(KEY_LAST_MODIFIED_BY) &&
                <Table.Cell>{account.last_modified_by}</Table.Cell>
            }
            <Table.Cell>
                <Account
                    name={account.name}
                    id={account.id}
                    account={account}
                    linkToAccount={
                        <Link to={`/${account.account_type === 'Master account' ? 'master_account' : 'account'}/${account.id}`}>{account.name}</Link>
                    }
                    isActionsVisible={account.account_type !== 'Master account'}
                />
            </Table.Cell>
            {
                !isColumnHidden(KEY_ACCOUNT_TYPE) &&
                <Table.Cell>
                    <b>{StringUtils.capitalize(account.account_type)}</b>
                </Table.Cell>
            }
            {
                !isColumnHidden(KEY_OWNER) &&
                <Table.Cell>
                    {account.owner && <Person username={account.owner.name} email={account.owner.email} />}
                </Table.Cell>
            }
            {
                isDyl && !isColumnHidden(KEY_FUND_TYPE) && (
                    <Table.Cell>
                        <Header as='h5'>
                            {StringUtils.capitalize(account.fund_type)}
                        </Header>
                    </Table.Cell>
                )
            }
            {!isColumnHidden(KEY_CUSTOMER_SINCE) && (
                <Table.Cell>
                    <div><b>{account.customer_since && DateTimeUtils.formatEpoch(account.customer_since, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                    <div>{account.customer_since && DateTimeUtils.formatEpoch(account.customer_since, DateTimeUtils.TIME_FORMAT)}</div>
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_CANCELED_ON) && (
                <Table.Cell>
                    <div><b>{account.canceled_on && DateTimeUtils.formatEpoch(account.canceled_on, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                    <div>{account.canceled_on && DateTimeUtils.formatEpoch(account.canceled_on, DateTimeUtils.TIME_FORMAT)}</div>
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_CANCELED_BY) && (
                <Table.Cell>{account.canceled_by}</Table.Cell>
            )}
            {!isColumnHidden(KEY_REACTIVATED_ON) && (
                <Table.Cell>
                    <div><b>{account.reactivated_on && DateTimeUtils.formatEpoch(account.reactivated_on, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                    <div>{account.reactivated_on && DateTimeUtils.formatEpoch(account.reactivated_on, DateTimeUtils.TIME_FORMAT)}</div>
                </Table.Cell>
            )}
            {!isColumnHidden(KEY_REACTIVATED_BY) && (
                <Table.Cell>{account.reactivated_by}</Table.Cell>
            )}
            {!isColumnHidden(KEY_ACCOUNT_STATUS) &&
                <Table.Cell className='Account__status'>
                    {getAccountStatus(account)}
                </Table.Cell>
            }
            {!isColumnHidden(KEY_ACTIONS) &&
                <Table.Cell>
                    {((account.account_type !== 'household' && account.account_type !== 'Master account') || (account.account_type === 'Master account' && parseInt(account.account_status) === 0)) &&
                        <Ref innerRef={ref}>
                            <EllipsisDropdownPortal reference={ref}>
                                {createActionOptions(
                                    false,
                                    account.account_type,
                                    () => { onLinkSelect(account.id); },
                                    null,
                                    () => { onDeleteMasterAccountSelect(actionPopupRef, account.id); }
                                ).map(({ text, icon, ...otherProps }, idx) =>
                                    <Ref innerRef={actionPopupRef}>
                                        <DropdownItem
                                            key={idx}
                                            text={text}
                                            icon={icon}
                                            {...otherProps}
                                        />
                                    </Ref>
                                )}
                            </EllipsisDropdownPortal>
                        </Ref>
                    }
                </Table.Cell>
            }
        </RowComponent>
    );
}
