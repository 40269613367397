import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Modal, RecordsTable } from 'dyl-components';

import LeadsHeader from './Header';
import LeadsRow from './LeadsRow';
import AddLeadModal from 'shared/modals/AddLeadModal';
import FullScreenModalPopUp from 'shared/FullScreenModalPopUp';

const LeadsTable = ({ onFilter, isColumnHidden, onPageChange, filterQueryParameters }) => {
    const [params] = useSearchParams();

    const { count, leads = [], isReadingLeads } = useSelector(state => state.leads);
    const [isAddLeadModalOpen, setIsAddLeadModalOpen] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    
    const onOpenAddLeadModal = () => {
        setIsAddLeadModalOpen(true);
    }

    const onCloseAddLeadModal = () => {
        setIsAddLeadModalOpen(false);
    }
    return (
        <React.Fragment>

            <RecordsTable
                tableBodyClassName='ModulesListTable'
                isTableLoading={isReadingLeads}
                isSearchResultEmpty={count === 0 && (params.get('search') || params.get('start') || params.get('end') || params.get('account_type') || params.get('owned_by'))}
                isTableEmpty={count === 0 && !(params.get('search') || params.get('start') || params.get('end') || params.get('account_type') || params.get('owned_by'))}
                TableHeader={<LeadsHeader isColumnHidden={isColumnHidden} onFilter={onFilter} />}
                TableRows={leads.map(lead => (
                    <LeadsRow
                        key={lead.pipeline_id}
                        lead={lead}
                        isColumnHidden={isColumnHidden}
                        filterQueryParameters={filterQueryParameters}
                    />
                ))}
                recordsCount={count}
                activePage={params.get('page')}
                onPageChange={onPageChange}
                recordsName='Lead'
                onClick={onOpenAddLeadModal}
            />
            <Modal open={isAddLeadModalOpen} onClose={() => {
                isDirty
                    ? setIsConfirmationOpen(true)
                    : onCloseAddLeadModal();
            }}>
                <AddLeadModal onOpen={() => { return Promise.resolve(); }} onClose={onCloseAddLeadModal} onDirtyChange={(dirty) => setIsDirty(dirty)} />
            </Modal>
            <FullScreenModalPopUp
                header={"Changes not saved"}
                subheader={"Are you sure you want to exit?"}
                isOpen={isConfirmationOpen}
                onConfirm={() => {
                    onCloseAddLeadModal();
                    setIsConfirmationOpen(false);
                    setIsDirty(false)
                }}
                onFormClose={() => setIsConfirmationOpen(false)}
                closeIcon={false}
            />
        </React.Fragment>
    );
}

export default LeadsTable;
